import React, { Component } from "react";
import { Container, Row, Col, Input, Button, Label, FormGroup, FormFeedback } from "reactstrap";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import CustomCheckBoxLabelPlain from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabelPlain";
import sideImage from "../../../assets/images/Group 426.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { Redirect } from "react-router";
import classnames from "classnames";
import Footer from "../../Components/Footer";
import { Post,Get } from "../../../utilities/axiosRequest";
import i18next from 'i18next';
import JoinPage2 from "./JoinPage2";

import Select from "react-select";
import { withTranslation } from 'react-i18next';
const phoneRegExp =/^(9665|\+9665|5|05)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(5, "Enter more than 5 characters")
        .max(25, "Allow only 25 characters")
        .required("Name is required"),


     mobileNumber: Yup.string()
         .required("Mobile number is required")
        .matches(phoneRegExp, 'Phone number is not valid'),
   sponserMobileNumber: Yup.string()
         .required("Mobile number is required")
        .matches(phoneRegExp, 'Phone number is not valid'), 
    sponserName: Yup.string()
        .min(3, "Enter more than 3 characters")
        .max(25, "Allow only 25 characters")
        .required("Sponser Name is required"),
    explanation: Yup.string()
        .min(5, "Enter more than 5 characters")
        .max(150, "Allow 150 characters only")
        .required("Explanation is required"),
 
});





class CompanyProfile extends Component {

    state = {
        message: "",
        information: {
           // workType: "Work",
          //  subWorkType: "Company",
           // name: "",
           // mobileNumber: "",
            service: ["Mobile"],
            explanation: "",
           // sponserName: "",
            //sponserMobileNumber: "",
          
            // gender: "Male",
            // country: "",
            // nationality: "",
            // contactType: "Mobile"
        },
		 isShow:"joinPage",
         country:[],
         city:[],
         services:[],
         selectedService:[],
         seltasktypes:[],
         selServiceIds:[],
         oldselServices:[],
        selTasks:[],
        country_id:1,
        city_id:1,
        country_code:'966',
    }

    constructor(props){
        super(props);
      

        this.gettingData = this.gettingData.bind(this);
      
    }
   getSubTask=(service_id)=>{
      console.log("Service id:",service_id)
        
        this.state.selectedService.push(''+service_id);
        this.setState({singleSelectedService:service_id})
          const selTasks=[];
            
          
            console.log("click on serevice.",this.state.selectedService);
           
         Get(`https://www.niddaa.com/backend/api/v1/servicetasks?selectedService=${this.state.selectedService}`).then(result =>{   result.selectedService.map(rec=>rec.tasks.map(a=>selTasks.push(a.task_id)));
             this.setState({seltasktypes:result.selectedService})});  
             // this.setState({selTasks:selTasks})
    }
    submitHandler = (values, resetForm) => {
        console.log(values);
        this.setState(prev => ({
            // ...prev,
            // information: values,
            message: "Sent Successfully,We will contact you soon"
        }));
        resetForm({});
    }
	
	checkBoxChangeHandler = ({ target: { value, name } }) => {
        this.setState({ [name]: value });
    }

    gettingData = (values) => {
        console.log(values)
        this.setState(prev => ({
            ...prev,
            ...values
        }));
    };

 changearabicToEng=(str,field)=> {
      const persianNumbers = ["۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹", "۰"]
      const arabicNumbers = ["١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩", "٠"]
      const englishNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
      
      str= str.split("").map(c => englishNumbers[persianNumbers.indexOf(c)] ||
          englishNumbers[arabicNumbers.indexOf(c)] || c).join("")
    this.setState({field:str})
     return str;
    };

  changeArabic = (event,setFieldValue  ) => {
   console.log("event",event)
   var number=this.changearabicToEng(event.target.value,'mobileNumber');
 console.log("afetr conversion",number)
     setFieldValue('mobileNumber',number)
     }

      changeArabicSponsorNumber = (event,setFieldValue  ) => {
   console.log("event",event)
   var number=this.changearabicToEng(event.target.value,'sponserMobileNumber');

     setFieldValue('sponserMobileNumber',number)
     }

     updateLoctaionCord = (lat,lng) => {
        this.setState({ lat: lat,lng:lng});
    

    }
    changeComponent = (componentName) => {
    console.log("Change component..",componentName);
        this.setState({ isShow: componentName });
    }

    /**  static getDerivedStateFromProps(props, state) {
        
           return {
                profile: state.companyinfo,
                isSearch: true,
            }
       
     
    }**/

    removeSubTask=(service_id)=>{
    console.log("remove");
        var array = this.state.selectedService;
        var index = array.indexOf(service_id); 

        if (index > -1) { 
          array.splice(index, 1);
        }
        
        this.setState({selectedService:array });
         Get(`https://www.niddaa.com/backend/api/v1/servicetasks?selectedService=${this.state.selectedService}`).then(result =>{ this.setState({seltasktypes:result.selectedService})});  
    }
    
    
    componentDidMount() {
          console.log("On paeeee")
       const customer_id= localStorage.getItem("customer_id");
       this.setState({ customer_id:customer_id });
        const countries=[];
        const cities=[];

        Get("https://www.niddaa.com/backend/api/v1/countries").then(result => { result.countries.forEach(function(data, idx) {
             countries.push({'value':data.country_id, 'label':data.country_name});   
             });
       
          this.setState({ country:countries });
        });
        Get("https://www.niddaa.com/backend/api/v1/cities").then(result =>{
             result.cities.forEach(function(data, idx) {
             cities.push({'value':data.city_id, 'label':data.city_name});   
             });
           this.setState({city: cities })
        }
        );

       const default_city=1;
        Get(`https://www.niddaa.com/backend/api/v1/services?&city_id=${default_city}`).then(result => this.setState({services: result.cityservices }));  

		 Get(`https://www.niddaa.com/backend/api/v1/store/company-info`).then(result => {
         console.log("compan profile",result.storeservices)
            if(result.result=="success" ) {
          
            this.gettingData(result.storeinfo[0]);
           const city_id= result.storeinfo[0].city_id;

           Get(`https://www.niddaa.com/backend/api/v1/services?&city_id=${city_id}`).then(result =>{ console.log("city servissssces",result.cityservices[0].loc_lang); this.setState({services: result.cityservices, lat: result.cityservices[0].loc_lat, lng: result.cityservices[0].loc_lang})}
         );  
        

            const selServices=[];
             const selTasks=[];
      
            result.storeservices.map(serv=>  selServices.push( serv.service_title));
            result.selectedtsktypes.map(serv=>  selTasks.push(''+serv.task_type_id));
            


            this.setState({selTasks:selTasks})
            this.setState({selectedService:selServices}) 
            this.setState({  oldselServices:selServices})
          
          

           
            Get(`https://www.niddaa.com/backend/api/v1/servicetasks?selectedService=${this.state.selectedService}`).then(result =>{ 
                this.setState({seltasktypes:result.selectedService})}); 
            this.setState({loc_longittude:result.storeinfo[0].loc_longitude !=undefined ? result.storeinfo[0].loc_longitude : null}) 
            }else{
                this.props.history("/home");
            }
         }
         ); 



      }
     submitHandler = (values, resetForm) => {
         this.gettingData(values);
        this.changeComponent("joinPage2");
      
    };

    updateCompany=(values)=>{
       console.log("Update sores...",values)
       Post(`https://www.niddaa.com/backend/api/v1/user/update-store`,this.state).then((result)=> {
        console.log("Result...",result.message)
        this.setState({message:result.message})
           
       }
       ); 
 
   }

     filterServices = (selected, setFieldValue) => {
         setFieldValue('city_id', selected);
         this.setState({ city_id:selected });
         Get(`https://www.niddaa.com/backend/api/v1/services?&city_id=${selected}`).then(result =>{ console.log("city servissssces",result.cityservices[0].loc_lang); this.setState({services: result.cityservices, lat: result.cityservices[0].loc_lat, lng: result.cityservices[0].loc_lang})}
         );  
    }
     filterCities=(selected,setFieldValue)=>{
      const cities=[];
        setFieldValue('country_id', selected);
        this.setState({ country_id:selected });

        Get(`https://www.niddaa.com/backend/api/v1/country-info?&country_id=${selected}`).then(result =>{

               this.setState({country_code: result.countryinfo[0].country_code,support_number:result.countryinfo[0].support_number })
            }
         ); 

       Get(`https://www.niddaa.com/backend/api/v1/cities?&country_id=${selected}`).then(result =>{
                 result.cities.forEach(function(data, idx) {
                 cities.push({'value':data.city_id, 'label':i18next.language=="en" ? data.city_name : data.city_name_ar});   
                 });
               this.setState({city: cities })
            }
         );



      // Get(`https://www.niddaa.com/backend/api/v1/services?&city_id=${selected}`).then(result => this.setState({services: result.cityservices }));  

     }

    render() {
        const {t}=this.props;
       const token = localStorage.getItem("token");
        const { deviceType, isShow, serviceType, message ,services,country,city,sponsor_name,mobile_no,company_or_individual,person_name,company_or_driver,selectedService,sponsor_mobile,seltasktypes,selTasks,description,country_id,city_id, oldselServices,country_code} = this.state;
       
		
        if (localStorage.getItem("token")=="") return <Redirect to="/home" from="*" />
        const arr2=[];
        const selectedService1=oldselServices.concat(arr2)
     
        return (
            <>

                <Container>
                    <Row className="my-5 mb-8">

                     {isShow === "joinPage2" && <JoinPage2
                            changeComponent={this.changeComponent}
                            country={country}
                            city={city}
                            updateCompany={this.updateCompany}
                            gettingData={this.gettingData}
                            updateLoctaionCord={this.updateLoctaionCord}
                            checkBoxChangeHandler={this.checkBoxChangeHandler}
                            message={message}
                            formData={this.state}
                            selected={deviceType} />}

                    {isShow === "joinPage" &&
                    <Formik
                      enableReinitialize
					initialValues={{
					workType: company_or_driver!=undefined? company_or_driver==1 ? 'Work' : 'Driver' : '',
					name:person_name!=undefined ? person_name : "",
					subWorkType:company_or_individual!=undefined  ? company_or_individual==1? 'Company' : 'Individual' : '',
                    
					service: selectedService1.length ?  selectedService1:[],
                   country_id:country_id,
                   city_id:city_id,
                   task:selTasks != undefined? selTasks :[],

					sponserMobileNumber:sponsor_mobile!=undefined ? parseInt(sponsor_mobile, 10) : '',
					mobileNumber: parseInt(this.state.mobile_no, 10) ,
					sponserName: sponsor_name!=undefined ? sponsor_name : "",
                    explanation:description!=undefined? description : ""

					}}
                validationSchema={validationSchema}
                onSubmit={
                    (values, { resetForm }) => this.submitHandler(values, resetForm)
                }>
                {({
                   errors,
                    values,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                      enableReinitialize
                }) => (
                    <>
                        <Col md="6" className="mb-3">
                                    <Row>
                                        <Col>
                                            <FormGroup className="d-flex flex-wrap">
                                                <CustomCheckBoxLabel
                                                    groupName="workType"
                                                    classNames="flex-grow-1 mx-1"
                                                    label={t('Work')}
                                                    value="Work"
                                                    active={values.workType}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                <CustomCheckBoxLabel
                                                    groupName="workType"
                                                    label={t('Driver')}
                                                    value="Driver"
                                                    classNames="flex-grow-1 mx-1"
                                                    active={values.workType}
                                                     onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                <FormFeedback>{touched.workType && errors.workType}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        {values.workType === "Work" && <Col xs="12">
                                            <FormGroup className="d-flex flex-wrap">
                                                <CustomCheckBoxLabelPlain
                                                    groupName="subWorkType"
                                                    classNames="mx-1"
                                                    label={t('Company')}
                                                    active={values.subWorkType}
                                                    value="Company"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                <CustomCheckBoxLabelPlain
                                                    groupName="subWorkType"
                                                    label={t('An Individual')}
                                                    classNames="mx-1"
                                                    active={values.subWorkType}
                                                    value="Individual"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                <FormFeedback>{touched.subWorkType && errors.subWorkType}</FormFeedback>
                                            </FormGroup>
                                        </Col>}
                                        <Col sm="12" className="mb-3">
                                            <FormGroup>
                                                <Label className={i18next.language=="ar" ? 'text-right pr-1 font-small' : 'font-small' } >{t('Company Name')}</Label>
                                                <Input
                                                    invalid={errors.name && touched.name}
                                                    placeholder={t('Company Name')}
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    name="name"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.name && errors.name}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="12">
                                                    <Row>
                                                        <Col md="12" lg="6">
                                                           <FormGroup>
                                                            <Select
                                                                invalid={errors.country && touched.country}
                                                                className={classnames("BoxShadow mb-2", { 'select-element is-invalid': touched.country_id && errors.country_id })}
                                                                value={country.find(p => p.value === values.country_id) || null}
                                                                onChange={(value) => this.filterCities( value.value,setFieldValue)}

                                                                //onChange={(value) =>   setFieldValue("country_id", value.value)}
                                                                placeholder={t('signup')}
                                                                onBlur={handleBlur("country")}
                                                                name="country_id"
                                                                options={country} />
                                                            <FormFeedback> {touched.country_id && errors.country_id}</FormFeedback>
                                                        </FormGroup>
                                                        </Col>
                                                        <Col md="12" lg="6">
                                                            <FormGroup>
                                                                <div className="d-flex flex-wrap mb-2">
                                                                   <Select
                                                                    className={classnames("BoxShadow flex-grow-1", { 'select-element is-invalid': touched.city_id && errors.city_id })}
                                                                    value={city.find(p => p.value === values.city_id) || null}
                                                                 
                                                                    onChange={(value) => this.filterServices( value.value,setFieldValue)}
                                                                    
                                                                    placeholder="Select City..."
                                                                    onBlur={handleBlur("city")}
                                                                    name="city_id"
                                                                    options={city} />
                                                                <FormFeedback className="w-100">{touched.city_id && errors.city_id}</FormFeedback>
                                                                </div>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                         <Col xs="12" className="mb-3">  
                                         <Row>
                                                      <Col xs="9" md="9" lg="9">
                                                       <div className="d-flex flex-wrap mt-2">
                                                         <Label className={i18next.language=="ar" ? 'text-right pr-1 font-small' : 'font-small' } >{t('Company Mobile Number')}</Label>
                                                           <FormGroup>
                                                            <Input
                                                                invalid={errors.mobileNumber && touched.mobileNumber}
                                                                placeholder={t('Company Mobile Number')}
                                                                value={values.mobileNumber}
                                                                onChange={(value) => setFieldValue("mobileNumber", value.value)}
                                                                onChange={ev => this.changeArabic(ev, setFieldValue)}
                                                                name="mobileNumber"
                                                                type="text"
                                                                maxlength="10"

                                                                onBlur={handleBlur("mobileNumber")}
                                                                className="BoxShadow mb-2 d-block" />
                                                            <FormFeedback>{touched.mobileNumber && errors.mobileNumber}</FormFeedback>
                                                             </FormGroup>
                                                        </div>
                                                    </Col>
                                                     <Col xs="3" md="3" lg="3" className="d-flex pt-2 ">
                                                          <span className="country-code-edit">{country_code}+</span>
                                                     </Col>

                                                </Row>
                                           </Col>
                                       {/** <Col sm="12" className="mb-3">
                                          <Label className={i18next.language=="ar" ? 'text-right pr-1 font-small' : 'font-small' } >{t('Company Mobile Number')}</Label>
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.mobileNumber && touched.mobileNumber}
                                                    placeholder={t('Company Mobile Number')}
                                                    value={values.mobileNumber}
                                                    onChange={handleChange}
                                                    name="mobileNumber"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.mobileNumber && errors.mobileNumber}</FormFeedback>
                                            </FormGroup>
                                        </Col>**/}
                                        <Col sm="12" className="mb-3">
                                          <Label className={i18next.language=="ar" ? 'text-right pr-1 font-small' : 'font-small' } >{t('Sponser Name')}</Label>
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.sponserName && touched.sponserName}
                                                    placeholder={t('Sponser Name')}
                                                    value={values.sponserName}
                                                    onChange={handleChange}
                                                    name="sponserName"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.sponserName && errors.sponserName}</FormFeedback>
                                            </FormGroup>
                                        </Col>

                                         <Col xs="12" className="mb-3">  
                                         <Row>
                                                      <Col xs="9" md="9" lg="9">
                                                       <div className="d-flex flex-wrap mt-2">
                                                         <Label className={i18next.language=="ar" ? 'text-right pr-1 font-small' : 'font-small' } >{t('Company Mobile Number')}</Label>
                                                           <FormGroup>
                                                            <Input
                                                                invalid={errors.sponserMobileNumber && touched.sponserMobileNumber}
                                                                placeholder={t('Mobile Number')}
                                                                value={values.sponserMobileNumber}
                                                                onChange={(value) => setFieldValue("sponserMobileNumber", value.value)}
                                                                onChange={ev => this.changeArabicSponsorNumber (ev, setFieldValue)}
                                                                name="sponserMobileNumber"
                                                                type="text"
                                                                maxlength="10"

                                                                onBlur={handleBlur("sponserMobileNumber")}
                                                                className="BoxShadow mb-2 d-block" />
                                                            <FormFeedback>{touched.sponserMobileNumber && errors.sponserMobileNumber}</FormFeedback>
                                                             </FormGroup>
                                                        </div>
                                                    </Col>
                                                     <Col xs="3" md="3" lg="3" className="d-flex pt-2 ">
                                                          <span className="country-code-edit">{country_code}+</span>
                                                     </Col>

                                                </Row>
                                           </Col>


                                       {/** <Col sm="12" className="mb-3">
                                        <Label className={i18next.language=="ar" ? 'text-right pr-1 font-small' : 'font-small' } >{t('Sponsor Mobile Number')}</Label>
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.sponserMobileNumber && touched.sponserMobileNumber}
                                                    placeholder={t('Mobile Number')}
                                                    value={values.sponserMobileNumber}
                                                    onChange={handleChange}
                                                    name="sponserMobileNumber"
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.sponserMobileNumber && errors.sponserMobileNumber}</FormFeedback>
                                            </FormGroup>
                                        </Col>**/}
                                        {values.workType === "Work" && <Col sm="12" className="mb-3">
                                            { i18next.language=='en' ?
                                             <p className="font-weight-bold">{t('The areas i mastered')}</p>
                                            :
                                             <p className="font-weight-bold text-right">{t('The areas i mastered')}</p>
                                             }
                                            <Row>
                                                {
                                                    services.map(service => (
                                                        <Col key={service.service_id} xs="6" sm="4" md="6" className="d-flex flex-wrap align-items-center">
                                                            <Label className={classnames("flex-grow-1 MenuTabs")}>
                                                                <div className="d-flex align-items-center CustomCheckBox">
                                                                    <input
                                                                        name="service"
                                                                        onChange={handleChange}
                                                                        type="checkbox"
                                                                        className="customInput"
                                                                        value={service.service_title}
                                                                        onClick={(e)=>{values.service.includes(service.service_title) ? this.removeSubTask(e.target.value) : this.getSubTask(e.target.value)}}
                                                                        checked={values.service.includes(service.service_title)}
                                                                    />
                                                                  { i18next.language=='en' ?  <span className="checkmark"></span> : <span className="checkmark-ar"></span> }
                                                                    { i18next.language=='en' ?  service.service_title : service.service_title_ar }
                                                                </div>
                                                            </Label>
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                        </Col>}


                                        
                                        {seltasktypes.length>0 && <Col sm="12" className="mb-3">
                                            { i18next.language=='en' ?
                                             <p className="font-weight-bold">{t('Choose select types')}</p>
                                            :
                                             <p className="font-weight-bold text-right">{t('Choose select types')}</p>
                                             }
                                            <Row>
                                                {
                                                    seltasktypes.map(seltask =>(
                                                            
                                                     <p className="font-weight-bold text-right">{seltask.service_title_ar}</p>,
                                                      seltask.tasks!='undefined' && seltask.tasks.map(task => (
                                                      
                                                        <Col key={task.task_id} xs="6" sm="4" md="6" className="d-flex flex-wrap align-items-center">
                                                            <Label className={classnames("flex-grow-1 MenuTabs", { active: values.task.includes(task.task_id) })}>
                                                                <div className="d-flex align-items-center CustomCheckBox">
                                                                    <input
                                                                        name="task"
                                                                        invalid={errors.task && touched.task}
                                                                       onChange={handleChange}
                                                                       
                                                                        type="checkbox"
                                                                        className="customInput"
                                                                        onBlur={handleBlur}
                                                                        value={task.task_id}
                                                                       checked={values.task.includes(''+task.task_id)}
                                                                    />
                                                                      
                                                                   { i18next.language=='en' ?  <span className="checkmark"></span> : <span className="checkmark-ar"></span> }
                                                                  { i18next.language=='en' ? seltask.service_title : seltask.service_title_ar }  &nbsp;<i class="fa fa-arrow-left" aria-hidden="true"></i> &nbsp; { i18next.language=='en' ?  task.task_title : task.task_title_ar }
                                                                </div>

                                                            </Label>
                                                        </Col>
                                                   ))
                                             
                                             ) )

                                                }

                                            </Row>
                                        </Col>}
                                        
                                        <Col sm="12" className="mb-3">
                                          <Label className={i18next.language=="ar" ? 'text-right pr-1 font-small' : 'font-small' } >{t('Explain')}</Label>
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.explanation && touched.explanation}
                                                    placeholder={t('Explain')}
                                                    value={values.explanation}
                                                    onChange={handleChange}
                                                    name="explanation"
                                                    type="textarea"
                                                    onBlur={handleBlur}
                                                    className="BoxShadow" />
                                                <FormFeedback>{touched.explanation && errors.explanation}</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                       
                                    </Row>
                                </Col>
                                <Col md="6" className="d-none d-md-block mb-3 align-self-center">
                                    <img src={sideImage} alt="" className="img-fluid" />
                                </Col>
                                <Col sm="12" className="text-center">
                                    <Button
                                        className="RequestBtn px-4 mb-2 font-weight-bolder"
                                        onClick={handleSubmit}>
                                       {t('Next')}
                                        </Button>
                                 
                                </Col>
                       </>   
                    )
                }
            </Formik>
              
                    }    
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}
export default withTranslation()(CompanyProfile);
