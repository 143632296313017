import React from 'react';

//if needed give it absolute position via css and raise z index
const Loader = ({ message1, message2, message3 }) => {
  const message1_en = "Immediate Maintenance and Services";
  const message2_en = "Select the service and we will contact you immediately";
  const message3_en = "...Loading services";

  const message1_ar = "صيانة وخدمات فورية";
  const message2_ar = "حدد الخدمة وسنصل إليك عاجلا";
  const message3_ar = "جاري تحميل الخدمات....";

  const message1_fr = "Maintenance et services immédiats";
  const message2_fr = "Sélectionnez le service et nous vous contacterons immédiatement";
  const message3_fr = "...Chargement des services en cours";

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      textAlign: 'center',
      padding: '10px',
      flexDirection: 'column'
    }}>
      <div><img src={require(`./assets/images/headerLogo.png`)} alt="" className={"deviceImage img-fluid"} /></div>
      {message1_ar}
      <p> {message2_ar}</p>
      <p> {message3_ar}</p>
      <br></br>
      <br></br>

      <p> {message1_en}</p>
      <p> {message2_en}</p>
      <p> {message3_en}</p>
      <br></br>
      <br></br>
      <p> {message1_fr}</p>
      <p> {message2_fr}</p>
      <p> {message3_fr}</p>
    </div>
  )
}

export default Loader;