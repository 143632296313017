import React from "react";
import { Label } from "reactstrap";
import "./CustomCheckBoxLabelCss.css";
import classnames from "classnames";
import i18next from 'i18next';
//import MobileImage from "../../../assets/images/mobile.png"
const CustomCheckBoxLabel = (props) => {
    const { label, classNames, value, groupName, onChange, active, onBlur, image, divStyle, extraStyle } = props;
    return (
        <Label className={classnames("MenuTabs mt-2 p-1", extraStyle, classNames, { active: active === value })} >
            <div className={"d-flex align-items-center CustomCheckBox  " + divStyle}>
                {
                    groupName === "deviceType" &&

                    <div><img src={"https://niddaa.com/niddaa_backend/public/assets/uploads/" + image} alt="" className={"deviceImage img-fluid"} /></div>
                }
                <input
                    name={groupName}
                    onChange={onChange}
                    value={value}
                    className={classnames("customInput", { "d-none": groupName === "deviceType" })}
                    type="radio"
                    id={label}
                    onBlur={onBlur}
                    checked={active === value} />
                {i18next.language == "en" ?
                    <span className={classnames("checkmark", { "d-none": groupName === "deviceType" })}></span>
                    :
                    i18next.language == "ar" ?
                        <span className={classnames("ml-2 checkmark-ar", { "d-none": groupName === "deviceType" })}></span>
                        :
                        <span className={classnames("checkmark", { "d-none": groupName === "deviceType" })}></span>
                }
                {label}
            </div>
        </Label>
    );
}
export default CustomCheckBoxLabel;