import React, { Component } from "react";
import { Container, Row, Col, Input, Button, Label, FormGroup, FormFeedback } from "reactstrap";
import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import sideImage from "../../../assets/images/Group 426.png";
import { Formik } from "formik";
import * as Yup from "yup";
import JoinPage from "./JoinPage";
import JoinPage2 from "./JoinPage2";
import axios from 'axios';
import classnames from "classnames";
import Footer from "../../Components/Footer";
import { Post,Get } from "../../../utilities/axiosRequest";
import ToastBox from "../../Components/ToastBox/ToastBox";
import i18next from 'i18next';
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(5, "Enter more than 5 characters")
        .max(25, "Allow only 25 characters")
        .required("Name is required"),
    mobileNumber: Yup.number()
        .typeError("That doesn't look like a phone number")
        .integer("A phone number can't include a decimal point")
        .min(1000000000, "Enter valid mobile number")
        .max(9999999999, "Enter valid mobile number")
        .required("Mobile number is required"),
    sponserMobileNumber: Yup.number()
        .typeError("That doesn't look like a phone number")
        .integer("A phone number can't include a decimal point")
        .min(1000000000, "Enter valid mobile number")
        .max(9999999999, "Enter valid mobile number")
        .required("Mobile number is required"),
    sponserName: Yup.string()
        .min(3, "Enter more than 3 characters")
        .max(25, "Allow only 25 characters")
        .required("Sponser Name is required"),
    explanation: Yup.string()
        .min(5, "Enter more than 5 characters")
        .max(150, "Allow 150 characters only")
        .required("Explanation is required")
  
});





class JoinToWork extends Component {

    state = {
        message: "",
        information: {
            workType: "Work",
            subWorkType: "Company",
            name: "",
            mobileNumber: "",
            service: ["Mobile"],
            explanation: "",
            sponserName: "",
            sponserMobileNumber: "",
           
            
            // gender: "Male",
            // country: "",
            // nationality: "",
            // contactType: "Mobile"
        },
		 isShow:"joinPage",
         country:[],
         city:[],
         services:[],
         service: [],
         show:false,
         spinnerLoading:false,
         userDuplicate:false,
         selectedService:[],
         seltasktypes:[],
         singleSelectedService:"",
         lat:'21.4249',
         lng:'39.850',
         country_code:'966'
    }

    submitHandler = (values, resetForm) => {
        console.log(values);
        this.setState(prev => ({
            // ...prev,
            // information: values,
            message: "Sent Successfully,We will contact you soon"
        }));
        resetForm({});
    }
    getSubTask=(service_id)=>{
        this.state.selectedService.push(service_id);
        this.setState({singleSelectedService:service_id})
        
         Get(`https://www.niddaa.com/backend/api/v1/servicetasks?selectedService=${this.state.selectedService}`).then(result =>{ this.setState({seltasktypes:result.selectedService})});  
    }

     changearabicToEng=(str,field)=> {
      const persianNumbers = ["۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹", "۰"]
      const arabicNumbers = ["١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩", "٠"]
      const englishNumbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"]
      
      str= str.split("").map(c => englishNumbers[persianNumbers.indexOf(c)] ||
          englishNumbers[arabicNumbers.indexOf(c)] || c).join("")
    this.setState({field:str})
     return str;
    };

  changeArabic = (event,setFieldValue  ) => {
   console.log("event",event)
   var number=this.changearabicToEng(event.target.value,'mobileNumber');
 console.log("afetr conversion",number)
     setFieldValue('mobileNumber',number)
     }


   changeArabicSponsorNumber = (event,setFieldValue  ) => {
   console.log("event",event)
   var number=this.changearabicToEng(event.target.value,'sponserMobileNumber');

     setFieldValue('sponserMobileNumber',number)
     }

  changeArabic = (event,setFieldValue  ) => {
     console.log("event",event)
     var number=this.changearabicToEng(event.target.value);
     console.log("afetr conversion",number)
     setFieldValue('mobileNumber',number)
     }

   removeSubTask=(service_id)=>{
        var array = this.state.selectedService;
        var index = array.indexOf(service_id); 

        if (index > -1) { 
          array.splice(index, 1);
        }
        
        this.setState({selectedService:array });
         Get(`https://www.niddaa.com/backend/api/v1/servicetasks?selectedService=${this.state.selectedService}`).then(result =>{ this.setState({seltasktypes:result.selectedService})});  
    }
	
	checkBoxChangeHandler = ({ target: { value, name } }) => {
        this.setState({ [name]: value });
    }

    gettingData = (values) => {
        console.log(values)
        this.setState(prev => ({
            ...prev,
            ...values
        }));
    };

    registerCompany=(values)=>{
        
        console.log("Full details....",this.state)
        this.setState({spinnerLoading:true})
       Post(`https://www.niddaa.com/backend/api/v1/user/create-store`,this.state).then((result)=> {
       console.log("Result...",result)
       if(result.errorfield=="email"){
            this.setState({message:i18next.language=="en" ? 'Email address is already taken' : " بريد هذا العميل مسجل من قبل"})
             this.setState({show:true,spinnerLoading:false,seltasktypes:[],selectedService:[]})
            return false;
       }
        if(result.errorfield=="username"){
            this.setState({message:i18next.language=="en" ? 'Username address is already taken' : "اسم المستخدم لهذا العميل مسجل من قبل"})
             this.setState({show:true,spinnerLoading:false,seltasktypes:[],selectedService:[]})
              return false;

       }
        if(result.errorfield=="mobile"){
            this.setState({message:i18next.language=="en" ? 'Mobile is already taken' : "رقم جوال هذا العميل مسجل من قبل"})
            this.setState({show:true,spinnerLoading:false,seltasktypes:[],selectedService:[]})
            return false;

       }
        this.setState({message:i18next.language=="en" ? 'Store is created success fully' : "تم تسجيل حسابك بنجاح"})
        this.setState({show:true,spinnerLoading:false,seltasktypes:[],selectedService:[]})
           
       }
       ); 
        
 
   }
 validateUsername=(username)=> {

       axios.get(`https://www.niddaa.com/backend/api/v1/user/duplicate-user?username=${username}`,{headers:{ 'Access-Control-Allow-Origin': '*'}}).then(result =>{
                        
                    if(result.data.duplicate){
                            this.setState({userDuplicate:true})

                        }else{

                              this.setState({userDuplicate:false})
                        }

                      
                      }

                      ); 
 }
    setShow=(status)=>{
       this.setState({ show: status, isShow:'joinPage' });

    }

filterServices = (selected, setFieldValue) => {
         setFieldValue('city_id', selected);
         this.setState({ city_id:selected });
         Get(`https://www.niddaa.com/backend/api/v1/services?&city_id=${selected}`).then(result =>{ console.log("city servissssces",result.cityservices[0].loc_lang); this.setState({services: result.cityservices, lat: result.cityservices[0].loc_lat, lng: result.cityservices[0].loc_lang})}
         );  
    }

     filterCities=(selected,setFieldValue)=>{
      const cities=[];
        setFieldValue('country_id', selected);
        this.setState({ country_id:selected });

        Get(`https://www.niddaa.com/backend/api/v1/country-info?&country_id=${selected}`).then(result =>{

               this.setState({country_code: result.countryinfo[0].country_code,support_number:result.countryinfo[0].support_number })
            }
         ); 

       Get(`https://www.niddaa.com/backend/api/v1/cities?&country_id=${selected}`).then(result =>{
                 result.cities.forEach(function(data, idx) {
                 cities.push({'value':data.city_id, 'label':i18next.language=="en" ? data.city_name : data.city_name_ar});   
                 });
               this.setState({city: cities })
            }
         );
      // Get(`https://www.niddaa.com/backend/api/v1/services?&city_id=${selected}`).then(result => this.setState({services: result.cityservices }));  

     }


     updateLoctaionCord = (lat,lng) => {
        this.setState({ lat: lat,lng:lng});
    

    }
    changeComponent = (componentName) => {
    console.log("Change component..",componentName);
    if(componentName=="joinPage"){
       // window.location.relaod();
        this.setState({ isShow: componentName,seltasktypes:[],selectedService:[] });
    }else{
         this.setState({ isShow: componentName });
    }
    }
    componentDidMount() {
          console.log("On paeeee")
       const customer_id= localStorage.getItem("customer_id");
       this.setState({ customer_id:customer_id });
        const countries=[];
        const cities=[];
        this.setState({seltasktypes:[]})
        Get("https://www.niddaa.com/backend/api/v1/countries").then(result => { result.countries.forEach(function(data, idx) {
             countries.push({'value':data.country_id, 'label':data.country_name});   
             });
       
          this.setState({ country:countries });
        });
        Get("https://www.niddaa.com/backend/api/v1/cities").then(result =>{
             result.cities.forEach(function(data, idx) {
             cities.push({'value':data.city_id, 'label':data.city_name});   
             });
           this.setState({city: cities })
        }
        );

       const default_city=1;
         Get(`https://www.niddaa.com/backend/api/v1/services?&city_id=${default_city}`).then(result => this.setState({services: result.cityservices }));  

      }


    render() {
       const token = localStorage.getItem("token");
        const { deviceType, isShow, serviceType, message ,services,country,city,show,spinnerLoading,userDuplicate,seltasktypes,selectedService,singleSelectedService,lat,lng,country_code,filterServices} = this.state;
        console.log("singleSelectedService....",singleSelectedService)
		console.log("Is show..",isShow);
        return (
            <>
                  <ToastBox show={show} message={message} setShow={this.setShow} />  
                <Container>
                    <Row className="my-5 mb-8">
                        {isShow === "joinPage" && <JoinPage
                            changeComponent={this.changeComponent}
                             gettingData ={this.gettingData }
                             services={services}
                             seltasktypes={seltasktypes}
                             updateLoctaionCord={this.updateLoctaionCord}
                             defaultLat={lat}
                             country={country}
                             city={city}
                             changeArabic={this.changeArabic}
                             country_code={country_code}
                             defaultLng={lng}
                             filterCities={this.filterCities}
                             filterServices={this.filterServices}
                             getSubTask={this.getSubTask}
                             selectedService={selectedService}
                             removeSubTask={this.removeSubTask}
                             singleSelectedService={singleSelectedService}
                            checkBoxChangeHandler={this.checkBoxChangeHandler}
                            selected={deviceType} />}
                         {isShow === "joinPage2" && <JoinPage2
                            changeComponent={this.changeComponent}
                            country={country}
                            city={city}
                            defaultLat={lat}
                            defaultLng={lng}
                            country_code={country_code}
                            changeArabicSponsorNumber={this.changeArabicSponsorNumber}
                            registerCompany={this.registerCompany}
                            gettingData={this.gettingData}
                            updateLoctaionCord={this.updateLoctaionCord}
                            spinnerLoading={spinnerLoading}
                            checkBoxChangeHandler={this.checkBoxChangeHandler}
                            message={message}
                            userDuplicate={userDuplicate}
                            validateUsername={this.validateUsername}
                            selected={deviceType} />}
              
                        
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
}
export default JoinToWork;