import React, { Component } from "react";
import { Container, Row, Col, Label, Input, Button, InputGroup, FormGroup, FormFeedback ,  Modal,
    ModalBody,
    ModalHeader} from "reactstrap";
import sideImage from "../../../assets/images/Group 426.png"
import classnames from "classnames";
import { Formik } from "formik";
import * as Yup from "yup";
import { Redirect } from "react-router";
import Footer from "../../Components/Footer";
import { Post,Get } from "../../../utilities/axiosRequest";
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { useHistory } from 'react-router-dom';
import ToastBox from "../../Components/ToastBox/ToastBox";
const validationSchema = Yup.object().shape({
    emailAddress: Yup.string()
           .required("E-mail or username is required"),
    password: Yup.string()
        .trim("No white space allow in starting")
        .required("Password is required")
});

class Login extends Component {
      static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };


    state = {
        showPassword: false,
         customer: this.props.cookies.get("user") || "",
         isShowForgotModal:false,
         show:false
    }

    loginHandler = (values) => {
     const { cookies } = this.props;
     const data = {
         emailAddress: values.emailAddress,
        password: values.password
        };
    const options = {
      headers: {
           'Access-Control-Allow-Origin': '*'
      }
    };
    axios.post('https://www.niddaa.com/backend/api/v1/user/login',data, options)
        .then(result => {
        console.log("Response..dddd.",result)
          
            if( result.data.accessToken==undefined){
           // this.setState({message:result.data.message})
             this.setState({message:i18next.language=="en" ? 'Invalid Username or Password' : "اسم المستخدم أو رقم المرور خطأ"});
                this.props.history.replace("/login");
            
            }else{
              localStorage.setItem("token", result.data.accessToken)
              localStorage.setItem("firstname",result.data.firstName)
              localStorage.setItem("mobile",result.data.mobile)
              localStorage.setItem("customer_id",result.data.customer_id)
              localStorage.setItem("lat",result.data.lat)
              localStorage.setItem("lang",result.data.lang)

              cookies.set("customer",result.data.user.id, { path: "/" ,expires: new Date(Date.now()+2592000)}); 
              
             cookies.set("user_type_id",result.data.user.user_type_id, { path: "/" ,expires: new Date(Date.now()+2592000)}); 

              //localStorage.setItem("store_user_id",result.data.user.store_user_id)
              //localStorage.setItem("user_type_id",result.data.user.user_type_id)
             //console.log("Successs",result.data)
                 if(result.data.user.user_type_id==2 ){
                    this.props.history.replace("/company-home");
                 }else if(result.data.user.user_type_id==1 ){
                    this.props.history.replace("/");
                }else if(result.data.user.user_type_id==3 ){
                    this.props.history.replace("/driver-home");

                 }else if(result.data.user.user_type_id==100 ){
                    this.props.history.replace("/admin-home");

                 }
              
            }
        } );
      
       
    }



 gettingData = (values) => {
        console.log(values)
        this.setState(prev => ({
            ...prev,
            ...values
        }));
    };


   onKeyDown=(e) => {
    if (e.keyCode === 13) {
        this.handleSubmit();
        e.preventDefault();
        e.stopPropagation();
    }
}

    forgotPassword = () => {
        //alert("Coming Soon...");
        this.setState({isShowForgotModal:true});
    }
    changeUsername=()=>{


    }

    setShow=(status)=>{
       this.setState({ show: status });

    }
   handleChangeText = e => {
        console.log(e);
        this.setState({ emailAddress: e.target.value });
        //this.props.onChange(e);
    };

    handleChangeMobileText = e => {
        console.log(e);
        this.setState({ mobileNo: e.target.value });
        //this.props.onChange(e);
    };
    sendForgot=()=>{
      if(this.state.emailAddress=="" && this.state.mobileNo==""){
          alert("Please fill email Address or mobile number");
          return false;
      }
      if(this.state.emailAddress!="" && this.state.emailAddress!=undefined){
      
       const data = {
         emailAddress: this.state.emailAddress,
       
        };
      const options = {
          headers: {
               'Access-Control-Allow-Origin': '*'
          }
         };
     axios.post('https://www.niddaa.com/backend/api/v1/user/forgot-password',data)
        .then(result => {
            if(result.data.error){
               this.setState({message:i18next.language=="en" ? 'You are not registered with us' : "تم الحفظ"})
               this.setState({show:true})
            }else{
               this.setState({message:i18next.language=="ar" ? 'A verification email send to your '+result.data.email  : "تم الحفظ"})
              this.setState({show:true})
            }
          this.setState({isShowForgotModal:false}); 
        })
        }else{
        

           const data = {
            mobileNo: this.state.mobileNo,
       
         };
         const options = {
          headers: {
               'Access-Control-Allow-Origin': '*'
          }
         };
        axios.post('https://www.niddaa.com/backend/api/v1/user/forgot-password',data)
        .then(result => {
            if(result.data.error){
               this.setState({message:i18next.language=="en" ? 'You are not registered with us' : "تم الحفظ"})
               this.setState({show:true})
            }else{
               this.setState({message:i18next.language=="ar" ? 'A verification email send to your '+result.data.email : "تم الحفظ"})
              this.setState({show:true})
            }
          this.setState({isShowForgotModal:false}); 
        })

        }

    }
   
    render() {
         const {t}=this.props;
         const { cookies } = this.props;
         const user_type_id=this.props.cookies.get("user_type_id"); 
         
        const { showPassword,message,isShowForgotModal,show } = this.state;
        
        if (localStorage.getItem("token") && user_type_id) return <Redirect to="/" from="*" />
        return (
            <>
             <ToastBox show={show} message={message} setShow={this.setShow} /> 
             <Modal isOpen={isShowForgotModal} backdrop="static">
                    <ModalHeader toggle={() => this.setState({ isShowForgotModal: false })}>Forgot Password?</ModalHeader>
                    <ModalBody >
                        <Row>
                                
                            <Col xs="12" className="mb-4 d-flex">
                               {/** <Label>{t('Email Address')}</Label>**/}
                                <FormGroup>
                                    <Input
                                        
                                        type="text"
                                        value={this.state.emailAddress}
                                        name="emailAddress"
                                        onChange={this.handleChangeText}
                                        onBlur=""
                                        placeholder={t('Email Address')}
                                        className="BoxShadow pl-4" />

                                </FormGroup>
                            </Col>
                              <Col xs="12" className="mb-4 d-flex ml-4" style={{justifyContent:'center'}}>

                               <Label style={{textAlign:'center'}} className="ml-5">{t('OR')}</Label>
                              </Col>
                              <Col xs="12" className="mb-4 d-flex">
                             
                                <FormGroup>
                                    <Input
                                        
                                        type="text"
                                        value={this.state.mobileNo}
                                        name="mobileNo"
                                        onChange={this.handleChangeMobileText}
                                        onBlur=""
                                        placeholder={t('Mobile No')}
                                        className="BoxShadow pl-4" />

                                </FormGroup>
                            </Col>
                             <Col xs="12" className="text-center mt-1">
                                   <Button className="RequestBtn px-4 font-weight-bolder" onClick={this.sendForgot} >{t('Send')}</Button>
                             </Col>
                        </Row>
                    </ModalBody>
                </Modal>

                <Container>
                    <Row className="loginPage">
                        <Col md="6" className="align-self-center">
                           
                            <h3 style={{ color: "darknavy" ,textAlign:"center"}}>{t('Roving Maintenance')}</h3>
                             {message !== "" &&
                           
                                <p className="m-0 text-muted text-center text-dark-red">
                                    {   message 
                                       
                                       }
                                  
                                </p>
                              }
                            <Formik
                                initialValues={{
                                    emailAddress: "",
                                    password: ""
                                }}
                                validationSchema={validationSchema}
                                onSubmit={
                                    values => (this.loginHandler(values))
                                }
                                onKeydown={

                                 values => (this.loginHandler(values))
                                }
                                >
                                {({
                                    errors,
                                    values,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                }) => (
                                        <form>
                                            <Row className="mt-5">
                                                <Col xs="12" className="mb-4">
                                                     {/**  <Label className="d-flex">{t('Username')}</Label>**/}
                                                    <FormGroup>
                                                        <Input
                                                            invalid={errors.emailAddress && touched.emailAddress}
                                                            type="text"
                                                            value={values.emailAddress}
                                                            name="emailAddress"
                                                            onChange={handleChange("emailAddress")}
                                                            onBlur={handleBlur("emailAddress")}
                                                            placeholder={t('Username or Email')}
                                                            className="BoxShadow pl-4" />
                                                        <FormFeedback>{touched.emailAddress && errors.emailAddress}</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="12" >
                                                     {/**  <Label className="d-flex">{t('Password')}</Label>**/}
                                                    <FormGroup>
                                                        <InputGroup className={classnames("BoxShadow", { "select-element is-invalid": errors.password && touched.password })}>
                                                            <Input
                                                                invalid={errors.password && touched.password}
                                                                autoComplete="off"
                                                                type={showPassword ? "text" : "password"}
                                                                value={values.password.trim()}
                                                                name="password"
                                                                onChange={handleChange("password")}
                                                                onBlur={handleBlur("password")}
                                                                placeholder={t('Password')}
                                                                className="pl-4 border-0 shadow-none" />
                                                            <i
                                                                className={classnames("passwordHideShow d-flex align-items-center pr-4", { "fa fa-eye-slash": !showPassword, "fa fa-eye": showPassword })}
                                                                onClick={() => this.setState((prev) => ({ showPassword: !prev.showPassword }))}></i>
                                                        </InputGroup>
                                                        <FormFeedback>{touched.password && errors.password}</FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs='12' className="mt-1 text-right">
                                                    <Button color="link" onClick={this.forgotPassword} className="shadow-none text-dark">{t('Forgot your Password')} ؟</Button>
                                                </Col>
                                                <Col xs="12" className="text-center mt-3">
                                                    <Button className="loginBtn px-4 font-weight-bolder"  onKeyPress= {handleSubmit} onClick={handleSubmit}>{t('Login')}</Button>
                                                </Col>
                                                 <Col xs='12' className="mt-1 text-right">
                                                    <span color="link" className="shadow-none text-dark pt-2">{t('Do yu have account')} </span> <Button color="link-login"  onClick={() => this.props.history.push('/signup')} className="shadow-none link-login ">{t('Create new account')} </Button>
                                                </Col>
                                                  <Col xs="12" className="join-wrapper-text mt-1 ">
                                                     <Button color="link-login"  onClick={() => this.props.history.push('/works')} className="shadow-none link-login mr-4">&nbsp;&nbsp;{t('Join To Us')} </Button>
                                                  
                                                </Col>
                                            </Row>
                                        </form>
                                    )}
                            </Formik>
                        </Col>
                        <Col md="6" className="d-none d-md-block align-self-center">
                            <img src={sideImage} alt="" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </>
        );
    }
};

export default withTranslation()(withCookies(Login));