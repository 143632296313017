import React, { Component } from "react";
import { Post,Get } from "../../../utilities/axiosRequest";
import {convertDate} from "../../Components/Common/Common";
import classnames from "classnames";
import {
    Container,
    Row,
    Col,
    Input,
    Button,
    Table,
    Badge,
    
    PaginationItem,
    PaginationLink,
    Modal,
    ModalBody,
    ModalHeader,
    FormGroup,
    FormFeedback
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ShowModalData from "./ShowModalData";
import { Formik } from "formik";


import CustomCheckBoxLabel from "../../Components/CustomCheckBoxLabel/CustomCheckBoxLabel";
import Pagination from "react-js-pagination";
import axios from 'axios';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
const device = [
    { value: "Mobile", label: "Mobile" }
];
const filter = [
    { label: "Country" },
    { label: "City" },
    { label: "Category" },
    { label: "Company Name" }
]
const divice_fixes = [
    {
        id: 7,
        label: "لن يتم الإصلاح"
    },
    {
        id: 8,
        label: "إصلاح وانتهاء"
    },
     {
        id: 9,
        label:"عمل معلق"
    }
];

class AdminReports extends Component {

    state = {
        isShowFilterModal: false,
        filteredBy: "",
        filteredBy1: "",
        selectedRecordInfo: null,
        deviceValue: 1,
        startingDate: "",
        endingDate: "",
        sDate: "",
        eDate: "",
        customerId: "",
        deviceId: "",
        customerName: "",
        mobileNumber: "",
        maintenanceRecords:[],
        device:[],
        activePage: 1,
        page:1,
        itemsCountPerPag:1,
        totalItemsCount:1,
        country_name:'',
        city_name:'',
        store_id:'',
        country:[],
        city:[],
        city_id:1,
        country_id:1,
        services:[],
       service_id:''
      

    }

    dateChangeHandler = (date, name) => {

        this.setState({ [name]: date });
      
    }
    valueChangeHandler = ({ target: { value, name } }) => {
        console.log(name, value)
        this.setState({ [name]: value })

    }
    infoHandler = (selectedInfo) => {
        this.setState({ selectedRecordInfo: selectedInfo })
    }
    modalTogglerHandler = () => {
        this.setState({ selectedRecordInfo: null });
    }

    handlePageChange(pageNumber) {
      
        this.setState({activePage: pageNumber});
        this.setState({page: pageNumber});


      axios.get('https://www.niddaa.com/backend/api/v1/admin/requestreports', {
          params: this.state
        },{headers:{ 'Access-Control-Allow-Origin': '*'}}).then(resp=>{
            console.log("Response pagination",resp.data.fixinginfo.data)
           this.setState({maintenanceRecords:resp.data.fixinginfo.data,totalItemsCount:resp.data.total});
           
        })


    }
        filterServices = (selected) => {
        const service=[];
         this.setState({ city_id:selected });
        /** Get(`https://www.niddaa.com/backend/api/v1/services?&city_id=${selected}`).then(result =>{ console.log("city servissssces",result.cityservices[0].loc_lang); this.setState({services: result.cityservices, lat: result.cityservices[0].loc_lat, lng: result.cityservices[0].loc_lang})}
         ); **/ 

          Get(`https://www.niddaa.com/backend/api/v1/services?&city_id=${selected}`).then(result => { if(result.cityservices)result.cityservices.forEach(function(data, idx) {
             service.push({'value':data.service_id, 'label':data.service_title});   
          });
          service.push({'value':'', 'label':'All'}); 
          this.setState({ device:service });
         
        });


         
    }

     
   searchWorkingReports = () => {
        const { startingDate, endingDate, city_id } = this.state;
       axios.get('https://www.niddaa.com/backend/api/v1/admin/adminreports', {
          params: this.state
        }).then(resp=>{
       
           this.setState({maintenanceRecords:resp.data.reportdata});
        }
        
        );
    }
     filterCities=(selected)=>{
      const cities=[];
        //setFieldValue('country_id', selected);
        this.setState({ country_id:selected });

        Get(`https://www.niddaa.com/backend/api/v1/country-info?&country_id=${selected}`).then(result =>{

               this.setState({country_code: result.countryinfo[0].country_code,support_number:result.countryinfo[0].support_number })
            }
         ); 

       Get(`https://www.niddaa.com/backend/api/v1/cities?&country_id=${selected}`).then(result =>{
                 result.cities.forEach(function(data, idx) {
                 cities.push({'value':data.city_id, 'label':i18next.language=="en" ? data.city_name : data.city_name_ar});   
                 });
               this.setState({city: cities })
            }
         );
     

     }

     componentDidMount() {
        const status=[];
       
        const service=[];
        const store_id= localStorage.getItem("store_user_id");
       /** Get(`https://www.niddaa.com/backend/api/v1/store/more-info?&store_id=${store_id}`).then(result => {
       
            if(result.storeinfo.length>0){
            this.setState({country_name:result.storeinfo[0].country_name,city_name:result.storeinfo[0].city_name});
            }
        })**/


         const default_city=1;
         Get(`https://www.niddaa.com/backend/api/v1/services?&city_id=${default_city}`).then(result => { if(result.cityservices)result.cityservices.forEach(function(data, idx) {
             service.push({'value':data.service_id, 'label':data.service_title});   
          });
           service.push({'value':'', 'label':'All'}); 
          this.setState({ device:service });
          console.log("Services...",result.cityservices)
        });

         
       // Get(`https://www.niddaa.com/backend/api/v1/store/fixing-status`).then(result => this.setState({fixingstatus:result.fixingstatus})); 
        Get("https://www.niddaa.com/backend/api/v1/store/fixing-status").then(result => { result.fixingstatus.forEach(function(data, idx) {
              status.push({'value':data.req_status_id, 'label':data.title});   
             });
       
          this.setState({ fixingstatus: status });
        });

       Get('https://www.niddaa.com/backend/api/v1/admin/adminreports', {
          params: this.state
        }).then(resp=>{
        console.log("Fixing info",resp);
           this.setState({maintenanceRecords:resp.reportdata,store_id:resp.store_id,total_requests:resp.total_requests});
           
        }
        
        );
        const countries=[];
        const cities=[];

        Get("https://www.niddaa.com/backend/api/v1/countries").then(result => { result.countries.forEach(function(data, idx) {
             countries.push({'value':data.country_id, 'label':data.country_name});   
             });
       
          this.setState({ country:countries });
        });
        Get("https://www.niddaa.com/backend/api/v1/cities").then(result =>{
             result.cities.forEach(function(data, idx) {
             cities.push({'value':data.city_id, 'label':data.city_name});   
             });
           this.setState({city: cities })
        }
        );


     }

    render() {
     const {t}=this.props;
        const {
            deviceValue,
            maintenanceRecords,
            selectedRecordInfo,
            startingDate,
            endingDate,
            customerId,
            deviceId,
            customerName,
            filteredBy,
            filteredBy1,
            mobileNumber,
            country_name,
            city_name,
            isShowFilterModal,device,totalItemsCount ,city,country,country_id,city_id,total_requests,services,service_id} = this.state;

            console.log("state in....maintanance report",maintenanceRecords)
             console.log("pagenumber",this.state.activePage)
        return (
            <>
                {
                    selectedRecordInfo ?
                        <Modal isOpen={selectedRecordInfo ? true : false} backdrop="static" className="modalStyle">
                            <ModalHeader toggle={this.modalTogglerHandler}>{t('Device Information')}</ModalHeader>
                            <ModalBody>
                                <ShowModalData selectedRecord={selectedRecordInfo} />
                            </ModalBody>
                        </Modal> : null
                }
                <Modal isOpen={isShowFilterModal} className="filterModalStyle" backdrop="static">
                    <ModalHeader toggle={() => this.setState({ isShowFilterModal: false })}>{t('Filtered By')}</ModalHeader>
                    <ModalBody >
                        <Row>
                            {filter.map(item => (
                                <Col key={item.label} xs="12" sm="6" md="6" className="d-flex align-items-center justify-content-center">
                                    <CustomCheckBoxLabel
                                        label={item.label}
                                        active={filteredBy}
                                        groupName="filteredBy"
                                        extraStyle="mx-2 flex-grow-1"
                                        onChange={this.valueChangeHandler}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </ModalBody>
                </Modal>
              
                 <Col md="10" xs="12" className="mb-2">
                    <Row className="mt-1 mb-3">
                      <Col md="3" className="mb-2">
                          <input type="checkbox" name="" id=""/>search by date
                      </Col>
                        <Col md="4" className="mb-2">
                            <Row>
                                <Col sm="6" className="mb-2">
                                    <Row>
                                        <Col xs='3'>
                                            <span className="font-weight-bold">From</span>
                                        </Col>
                                        <Col xs='9'>
                                            <DatePicker
                                                selected={startingDate}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => this.dateChangeHandler(date, "startingDate")}
                                                placeholderText={t('Select start date')}
                                                className="BoxShadow px-2 datePicker-1" />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm="6" className="mb-2">
                                    <Row>
                                        <Col xs='3'>
                                            <span className="font-weight-bold">To</span>
                                        </Col>
                                        <Col xs='9'>
                                            <DatePicker
                                                selected={endingDate}
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) => this.dateChangeHandler(date, "endingDate")}
                                                placeholderText={t('Select end date')}
                                                className="BoxShadow px-2 datePicker-1" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                           
                        </Col>
                     </Row>
                      <Row className="mt-1 mb-3"> 
                        <Col md="8" className="mb-3">
                            <Row>
                                <Col sm="5">
                                

                                    <Row>
                                      <Col md="12" lg="6">
                                      {/** <Select
                                        className="BoxShadow mb-2"
                                        value={country.find(p => p.value === country_id)}
                                        onChange={(value) => this.setState({ country_id: value.value })}
                                        placeholder={t('Countries')}
                                        name="country_id"
                                        options={country} />**/}

                                          <Select
                                               
                                                className="BoxShadow mb-2"
                                               value={country.find(p => p.value === country_id)}
                                                onChange={(value) => this.filterCities( value.value)}
                                                placeholder={t('Countres')}
                                               
                                                name="country_id"
                                                options={country} />

                                        </Col>
                                        <Col md="12" lg="6">

                                             <Select
                                                    className="BoxShadow mb-2"
                                                   value={city.find(p => p.value === city_id)}
                                                      
                                                  
                                                    onChange={(value) => this.filterServices( value.value)}
                                                    placeholder="Select City..."
                                                   
                                                    name="city_id"
                                                    options={city} />

                                          {/** <Select
                                            className="BoxShadow mb-2"
                                            value={city.find(p => p.value === city_id)}
                                            onChange={(value) => this.setState({ city_id: value.value })}
                                            placeholder={t('Cities')}
                                            name="city_id"
                                            options={city} />   **/}         
                                         </Col>
                                        </Row>



                                </Col>
                               <Col sm="3">
                                

                                    <Row>
                                      <Col md="12" lg="12">
                                       <Select
                                        className="BoxShadow mb-2"
                                        value={device.find(p => p.value === service_id)}
                                        onChange={(value) => this.setState({ service_id: value.value })}
                                        placeholder={t('Services')}
                                        name="service_id"
                                        options={device} />
                                        </Col>
                                       </Row>


                                </Col>

                                  <Col sm="3">
                                

                                    <Row>
                                      <Col md="12" lg="12">
                                       <Select
                                        className="BoxShadow mb-2"
                                        value={device.find(p => p.value === service_id)}
                                        onChange={(value) => this.setState({ service_id: value.value })}
                                        placeholder={t('Services')}
                                        name="service_id"
                                        options={device} />
                                        </Col>
                                       </Row>


                                </Col>
                                
                                
                          
                                <Col sm="1">
                                    <Button
                                        className="RequestBtn px-4 font-weight-bolder"
                                        onClick={this.searchWorkingReports}>{t('search')}</Button>

                                </Col>
                            </Row>


                        </Col>
                   
                    </Row>

                   


                    <Row>
                        <Col sm="12" class="d-flex" style={{justifyContent:'center',textAlign:'center',fontSize:'18px',fontWeight:'bold'}}>
                            <span>Company Working Reports</span>
                        </Col>
                    </Row>
                  

                    <div className="mb-4">
                        <Table className="mb-2 table-responsive-md" style={{ borderBottom: "1px solid #ff5900" }}>
                            <thead>
                                <tr style={{ backgroundColor: "rgb(115, 192, 241)", color: "white" }}>
                                    <th>{t('Company')}</th>
                                    <th>{t('Total Orders')}</th>
                                    <th>{t('Accept')}</th>
                                    <th>{t('RAccept')}</th>
                                    <th>{t('Refuse')}</th>
                                    <th>{t('Checking')}</th>
                                    <th>{t('Fixed')}</th>
                                    <th>{t('Not Fixed')}</th>
                                    <th>{t('Not Check')}</th>
                                    <th>{t('Company notdo')}</th>
                                    <th>{t('not add check cost')}</th>
                                    <th>{t('not add work cost')}</th>
                                    <th>{t('Rating')}</th>
                                    <th>{t('Work %')}</th>
                                    <th>{t('Checking %')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    maintenanceRecords.map((p, ind) => (
                                        <tr key={ind} onClick={() => this.infoHandler(p)}>
                                            <td>{p.companyName}</td>
                                            <td>{p.total_count}</td>
                                            <td>{p.total_accept_count}</td>
                                           {/* <td>
                                                <Badge className="px-2 py-1" style={{ backgroundColor: "#FF5900", borderRadius: "15px" }} >
                                                    {p.title}
                                                </Badge>
                                            </td>*/}
                                            <td>{p.total_refuse_after_accept}</td>
                                            <td>{p.total_refuse}</td>
                                            <td>{p.total_checking}</td>
                                            <td>{p.total_fixed}</td>
                                            <td>{p.total_notfixed}</td>
                                            <td>{p.total_not_check}</td>
                                             <td>{p.total_company_not_do}</td>
                                            <td>{p.not_add_check_cost}</td>
                                            <td>{p.not_add_serv_cost}</td>
                                            <td>1</td>
                                            <td>{(p.total_fixed/p.total_count)*100}%</td>
                                            <td>{(p.total_checking/p.total_count)*100}%</td>
                                          
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={5}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          itemClass="page-item" 
                          linkClass="page-link" 
                          onChange={this.handlePageChange.bind(this)}
                        />



                   
                    </div>

                </Col>
            </>
        );
    }
}

export default  withTranslation()(AdminReports);