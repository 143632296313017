import React, { Component } from "react";
import { Container, Row, Col, Input, Button, FormGroup, FormFeedback } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import OrderInformation from "./OrderInformation";
import SearchOrder from "./SearchOrder";
import { Post,Get } from "../../../utilities/axiosRequest";
import { withTranslation } from 'react-i18next';
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import axios from 'axios';
import i18next from 'i18next';
import ToastBox from "../../Components/ToastBox/ToastBox";
const phoneRegExp =/^(9665|\+9665|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/
const validationSchema = Yup.object().shape({
 
  /** mobileNumber: Yup.string()
         .required("Mobile number is required")
        .matches(phoneRegExp, 'Phone number is not valid')**/
  
});




class TrackOrder extends Component {
static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
    state = {
        mobileNumber: "",
        selectedOrder: null,
        deviceModel: "",
        isSearch: false,
        searchedDevice: [],
        logged:false,
        infoRequest:'',
        error:false,
        message:'',
        show:false,
        complaintType:'No',
        complaintTextDisabled: true
    }
    submitHandler = (values, resetForm) => {
        console.log(values)
        const requestId=values.requestId;
        const mobile_no=values.mobileNumber;
        if(requestId==undefined  && mobile_no=="" ){
            this.setState({error:true})

        }else{
            Get(`https://www.niddaa.com/backend/api/v1/user/request-info?&mobile_no=${mobile_no}&requestId=${requestId}`).then(result => { this.setState({searchedDevice:result.requestinfo});console.log(result.requestinfo)});  
           
            this.setState(prev => ({
                ...prev,
                ...values,
                isSearch: true,
                //searchedDevice,
            }));
        }
       
    }


    setShow=(status)=>{
       this.setState({ show: status });

    }
    checkBoxChangeHandler = ({ target: { value, name } }) => {
        this.setState({ [name]: value });
       
    }

    orderInformationHandler = (selectedOrder) => {
    console.log('Selectded order..',selectedOrder)
        this.setState({ selectedOrder: selectedOrder });
    }

    changeComplaintStatus=(status)=>{
       if(status=="No"){


            this.setState({complaintTextDisabled:true})

             axios.post("https://www.niddaa.com/backend/api/v1/user/change-complaint-status",{request_id:this.state.selectedOrder.request_id,status:status},{headers:{ 'Access-Control-Allow-Origin': '*'}}).then(result=>{

                console.log("change complaint status",result);

                });
        

       }else{

             this.setState({complaintTextDisabled:false})
              const { cookies } = this.props;
            console.log("Send complinat",cookies.get('customer'))
            if(cookies.get('customer') ==undefined){
                    this.setState({message:i18next.language=="en" ? 'Please login and send complaint' : 'لابد من التسجيل أولا قبل كتابة الشكوى' ,show:true})
                    return false;
            }
       }

    }

      componentDidMount () {

          const { cookies } = this.props;
           if( localStorage.getItem("token")!='' && cookies.get('customer')!=undefined){
           console.log("Cookies custome",cookies.get('customer'));

                Get(`https://www.niddaa.com/backend/api/v1/user/myrequestlists?customer=${cookies.get('customer')}`).then(result => { console.log("resquse..",result);this.setState({searchedDevice:result.requestlists, infoRequest:result.info}) });  
       

           }     
        }
       
    sendComplaint=(values)=>{
    const { cookies } = this.props;
    console.log("Send complinat",cookies.get('customer'))
    if(cookies.get('customer') ==undefined){
            this.setState({message:i18next.language=="en" ? 'Please login and send complaint' : 'لابد من التسجيل أولا قبل كتابة الشكوى'  ,show:true})
            return false;
    }
    const {requestId,complaintTxt,complaintType}=values;

        axios.post('https://www.niddaa.com/backend/api/v1/user/send-complaints',{requestId:requestId,complaintTxt:complaintTxt,complaintType:complaintType },{headers:{ 'Access-Control-Allow-Origin': '*'}}).then(result => {
        if(result.data.complaint=="success"){
            this.setState({message:i18next.language=="en" ? 'Sent complaint successfully' : 'تم إرسال الشكوى بنجاح' ,show:true})
        }else if(result.data.complaint=="exist"){

            this.setState({message:i18next.language=="en" ? 'You are not able to send another commnt untill admin reply' : 'تم الإرسال من قبل ولا يمكن إرسال شكوى أخرى حتى يتم الرد' ,show:true})

        }
      });  
    }
    render() {
        const { t } = this.props;
        const { searchedDevice, isSearch, selectedOrder, infoRequest,error,show,message,complaintType ,complaintTextDisabled} = this.state;
       
      console.log("Searched device..",searchedDevice)
       console.log("localStorage..",localStorage.getItem("token"))
        return (
            <Container>
                  <ToastBox show={show} message={message} setShow={this.setShow} />  

                {selectedOrder ?
                    <OrderInformation selectedOrder={selectedOrder}  sendComplaint={this.sendComplaint}  checkBoxChangeHandler={this.checkBoxChangeHandler} selected={complaintType
                    }  changeComplaintStatus={ this.changeComplaintStatus} complaintTextDisabled={complaintTextDisabled} />
                    : 
                   localStorage.getItem("token") !=null ? <SearchOrder  infoRequest ={ infoRequest}  searchedDevice={searchedDevice} handleClick={this.orderInformationHandler} />
                   :
                    <>
                        <Formik
                            initialValues={{
                                mobileNumber: "",
                                deviceModel: ""
                            }}
                            validationSchema={validationSchema}
                            onSubmit={
                                (values) => (this.submitHandler(values))
                            }>{({
                                errors,
                                values,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            }) => (

                                    <Row className="mt-3">
                                        <Col sm="5" className="mb-2">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.mobileNumber && touched.mobileNumber}
                                                    placeholder={this.props.t('رقم الجوال مع مفتاح  الدولة بدون الصفرين')}
                                                    value={values.mobileNumber}
                                                    onChange={handleChange("mobileNumber")}
                                                    name="mobileNumber"
                                                    type="text"
                                                    maxlength="14"
                                                    onBlur={handleBlur("mobileNumber")}
                                                    className="BoxShadow pl-3" />
                                                <FormFeedback>{touched.mobileNumber && errors.mobileNumber}</FormFeedback>
                                            </FormGroup>
                                               {error? <div class="error-info">{t('Please select search terms')}</div> : '' }
                                        </Col>
                                         <Col sm="1" className="mb-2" style={{textAlign:'center',lineHeight:'3em'}}>
                                            <span>{t('OR')}</span>
                                         </Col>
                                        <Col sm="5">
                                            <FormGroup>
                                                <Input
                                                    invalid={errors.requestId && touched.requestId}
                                                    placeholder={t('Request Id')}
                                                    value={values.requestId}
                                                    onChange={handleChange("requestId")}
                                                    name="requestId"
                                                    type="text"
                                                    onBlur={handleBlur("requestId")}
                                                    className="BoxShadow pl-3" />
                                                <FormFeedback>{touched.requestId && errors.requestId}</FormFeedback>
                                            </FormGroup>
                                        </Col>

                                        <Col sm="12" className="mt-3 text-center">
                                            <Button className="RequestBtn px-4 font-weight-bold" onClick={handleSubmit}>{t('Enquiry')}</Button>
                                        </Col>
                                       
                                    </Row>
                                )}
                        </Formik>
                        <Row className="px-3 mt-3">
                            {
                                isSearch ? searchedDevice.length ?
                                    <SearchOrder searchedDevice={searchedDevice} handleClick={this.orderInformationHandler} />
                                    : <Col><h5 className="text-center">Order Not Found</h5></Col> : null
                            }
                        </Row>
                    </>
                }
            </Container>
        );
    }
};
export default withTranslation()( withCookies(TrackOrder));
