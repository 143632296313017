import React, { Component } from "react";
export default class PopUp extends Component {
  handleClick = () => {
   this.props.togglePop();
  };
render() {
  return (
   <div className="popup-box">
     <div className="box">
     <span className="close" onClick={this.handleClick}>&times;    </span>
     <img src={`https://www.niddaa.com/backend/img/${this.props.img_link}`} />
    </div>
   </div>
  );
 }
}